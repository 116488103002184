(function(window, document, $) {
    /*
         Se incluir eprocAlertarErro em $.ajax(), exibe um alerta utilizando os atributos 'msg' e 'detalhes' do objeto padrão, que é gerado em DeclinacaoCompetenciaRN::tratarRetornoJSON
         O objeto padrão de retorno é montado utilizando o método
         (ex.:
         $.ajax({
            eprocAlertarErro:true,
            ...
         });
     */

    var EPROC_FD = {
        CONTAINER_CLASS: 'eproc-fd-file',
    };

    /**
     * Executa uma chamada padrão de AJAX/POST. Recebe um objeto que deve possuir as seguintes propriedades:
     * - result     : String|jQuery - O elemento que conterá o resultado da chamada
     * - el         : String|jQuery - O identificador do elemento atual (jquery)
     * - loadingMsg : (opcional) String que contém a mensagem de carregamento dos dados
     * - params     : Objeto        - os parâmetros para a chamada (attr. DATA do parâmetro do ajax)
     * - url        : String        - a url a ser chamada pelo POST
     * - success    : function      - Uma função que recebe como parâmetro os dados vindos do sucesso da execução do POST
     *
     * Exemplo.:
     * genericCall({
     *      el: '#cod_tipo_bem',
     *      result: '#result',
     *      params: {
     *        cod_tipo_bem: $(this).val()
     *      },
     *      url: '" . $this->objSessaoEproc->assinarLink('controlador_ajax.php?acao_ajax=bap_buscar_por_cod_tipo') . "'
     * });
     * @param attrs
     */
    function genericCall(attrs) {
        var el = attrs.el instanceof $ ? attrs.el : $(attrs.el);
        var val = $(el).val();
        if (!attrs.success) {
            attrs.success = function(data) {
                resultEl.html(data);
            };
        }
        var resultEl = attrs.result instanceof $ ? attrs.result : $(attrs.result);

        if (val) {
            genericAjax(attrs);
        } else {
            resultEl.html('');
        }
    }

    function genericAjax(attrs) {
        genericAjaxPromise(attrs);
    }

    function genericAjaxPromise(attrs) {
        let url = attrs.url;
        let params = attrs.params;
        let async = typeof attrs.async !== 'undefined' ? attrs.async : true;
        let loadingMsg = typeof attrs.loadingMsg !== 'undefined' ? attrs.loadingMsg : null;
        let success = typeof attrs.success !== 'undefined' ? attrs.success : null;

        return $.ajax({
            type: 'POST',
            data: params,
            url: url,
            async: async,
            eprocAlertarErro: true,
            beforeSend: function() {
                infraExibirAviso(false, loadingMsg);
            },
            success: success,
            complete: function() {
                if (attrs.cb && typeof attrs.cb == 'function') {
                    attrs.cb();
                }
                infraOcultarAviso();
            },
        });
    }

    function submitAjax(attrs) {

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: attrs.url,
            data: attrs.data,
            beforeSend: attrs.beforeSend ?? function() {
                $('.is-invalid').removeClass('is-invalid');
                $('.invalid-feedback').remove();
            },
            complete: attrs?.complete,
            success: attrs.success,
            eprocAlertarErro: attrs?.eprocAlertarErro,
            error: function(xhr, status, error) {
                infraOcultarAviso();
                let msgGeral = '';
                $.each(xhr.responseJSON, function(idField, validacoes) {
                    let field = null;
                    if (idField !== '') {
                        field = attrs.container ? attrs.container.find("#" + idField) : $("#" + idField);
                        field.addClass('is-invalid');
                    }
                    $.each(validacoes, function(idValidacao, validacao) {
                        if (field !== null && field.is(':visible')) {
                            let container = field.parents('.form-group');
                            if (field.is('select')) {
                                container = field.parents('.bootstrap-select');
                            }
                            if (field.is('.datetimepicker-input')) {
                                container = field.parents('.datepicker');
                            }
                            if (container.find('#' + idValidacao).length === 0) {
                                container.append('<div class="invalid-feedback" id="' + idValidacao + '">' + validacao
                                    + '</div>');
                            }
                        } else {
                            msgGeral += validacao + '<br>';
                        }
                    });
                });
                if (msgGeral !== '') {
                    let opts = {
                        text: msgGeral,
                        data: {
                            autohide: true,
                            delay: 10 * 1000,
                        },
                    };
                    if (xhr.status === 501) {
                        eproc.toast.warning(opts);
                    } else {
                        eproc.toast.error(opts);
                    }
                }
            },
        });
    }

    /**
     * @deprecated
     * Exibe um toast.
     * Esse método não deve ser chamado diretamente porque requer uma série de padrões (tanto na request quanto na response)
     * Atualmente, o "responseText" já contém o html necessário à montagem do toast.
     * Ver EprocINT:montarAreaErro (php)
     * @param responseText
     */
    function exibirAlertaBS4(responseText) {
        console.warn('Método depreciado; utilize eproc.toast.*')
        $(document.body).find('.container-toast').remove();
        $(document.body).append(responseText);
    }

    function errorHandler(e, xhr, settings) {
        console.warn('==============');
        console.warn('errorHandler:');
        console.warn(e);
        console.warn(xhr);
        console.warn(settings);
        console.warn('==============');

        //todo acho que só importa se é bootstrap no body, afinal o append vai ser nele
        var isBootstrap = true;

        if (settings.eprocAlertarErro) {
            if (xhr.hasOwnProperty('responseJSON')) {
                var data = xhr.responseJSON;
                if (!data.sucesso) {
                    alert(data.msg + '\n\n' + 'Detalhes: ' + data.detalhes);
                }
            } else {
                if (!isBootstrap) {
                    alert(xhr.responseText);
                } else {
                    const msgErro = xhr.responseText.infraReplaceAll('\\n', '<br>')
                    eproc.toast.error(`
                        <div class="d-flex overflow-auto mh-100">
                          <pre class="has-slim-scroll flex-grow-1"><code>${msgErro}</code></pre>
                        </div>
                    `)
                }
            }
        }
    }

    $(document).ajaxError(errorHandler);
    const CHECKBOX_TEXT_NOT_SELECTED = "Selecionar Tudo";
    const CHECKBOX_TEXT_SELECTED = "Remover Seleção";

    function marcarTodos(checkboxEl, tableId) {
        var img = $(checkboxEl).find('img');
        var checkboxesSelector = tableId + " tbody tr td input:checkbox";
        var isHeaderCheckboxActive = img.prop('title') == CHECKBOX_TEXT_SELECTED;

        if (isHeaderCheckboxActive) {
            $(checkboxesSelector + ':checked').each(function() {
                $(this).click();
            });
            img.attr('title', CHECKBOX_TEXT_NOT_SELECTED);
            img.attr('alt', CHECKBOX_TEXT_NOT_SELECTED);

        } else {
            $(checkboxesSelector + ':not(:checked)').each(function() {
                $(this).click();
            });
            img.attr('title', CHECKBOX_TEXT_SELECTED);
            img.attr('alt', CHECKBOX_TEXT_SELECTED);
        }
    }

    /**
     * Se o atributo data contiver um array de strings, tanto o valor quanto o texto do option serão o próprio texto.
     * Caso contrário, sendo o atributo 'data' um array de arrays/objetos, devem ser especificados os atributos name e value
     * @param dataObject
     */
    function getOptionsHtml(dataObject) {
        var data = dataObject.data;
        var name = dataObject.name;
        var value = dataObject.value;
        var empty = dataObject.vazio ? dataObject.vazio : "Sem resultados";
        var prompt = dataObject.prompt;
        var target = dataObject.target;
        var selectedValue = dataObject.selectedValue;

        var html = '';
        if (Array.isArray(data) && data.length > 0) {
            if (prompt) {
                html += createOption('', prompt);
            }
            data.forEach(function(d) {
                if (typeof d === 'string') {
                    html += createOption(d, d);
                } else if (Array.isArray(d) || typeof d === 'object') {
                    html += createOption(d[value], d[name]);
                } else {
                    throw ("o array 'data' deve conter strings ou arrays.");
                }
            });
        } else {
            html += createOption('', empty);
        }

        $(target).html(html);

        if (!isBlank(selectedValue) && $(target + " option[value=" + selectedValue + "]").length > 0) {
            $(target).val(selectedValue);
        }
        $(target).change();
    }

    function createOption(value, label) {
        return '<option value="' + value + '" >' + label + '</option>';
    }

    function areaUploadMultiplosArquivos(
        button_add_id,
        droppable_id,
        filelist_id,
        container_id,
        browser_support_id,
        namePrefix,
        bolComDescricao,
        bolAjax,
        strUrlAjaxUpload,
        callbackUploadArquivo,
        tamanhoMaximoArquivo,
    ) {

        var isShown = true;
        var isOver = false, interval;

        function dragoverHandler(e) {
            e.preventDefault();

            clearInterval(interval);

            interval = setInterval(function() {
                isOver = false;
                clearInterval(interval);

                hideOverlay()
            }, 100);

            if (!isOver) {
                isOver = true;

                showOverlay();
            }
        }

        var hideOverlay = function() {
            //console.log('hideOverlay' + isShown);
            if (isShown) {
                $('#' + droppable_id).fadeOut('fast');
                isShown = false;
            }
        };

        var showOverlay = function() {
            //console.log('showOverlay' + isShown);
            if (!isShown) {
                isShown = true;
                $('#' + droppable_id).fadeIn('fast');
            }
        };

        var uploadAjax = function(uploader, files) {
            var messageContainer = $('<div></div>');
            $(filelistContainer).append(messageContainer);
            var promise = $.Deferred();
            var filePromises = files.map(function(file) {
                var message = $('<h6>Fazendo upload do arquivo ' + file.name + '...</h6>');
                $(messageContainer).append(message);
                var filePromise = $.Deferred();
                var reader = new FileReader();
                reader.readAsDataURL(file.getSource().getSource());
                reader.onloadend = function() {
                    var dataUrl = reader.result;
                    var base64 = dataUrl.split(',')[1];
                    var data = {};
                    data[namePrefix] = {
                        type: file.type,
                        filename: file.name,
                        size: file.size,
                        content_base64: base64,
                    };
                    $.post({
                         url: strUrlAjaxUpload,
                         data: data,
                     })
                     .then(function(resposta) {
                         message.text('Terminou upload do arquivo ' + file.name + '...');
                         filePromise.resolve(resposta);
                     })
                     .catch(function(resposta) {
                         filePromise.reject(resposta);
                     });
                };
                return filePromise;
            });
            $.when.apply($, filePromises).always(function() {
                files = Array.prototype.slice.call(arguments);
                callbackUploadArquivo && typeof callbackUploadArquivo === 'function' && callbackUploadArquivo(files);
                messageContainer.remove();
            });
            return promise;

        };

        var filelistContainer = document.getElementById(filelist_id);
        var container = document.getElementById(container_id);
        var elButtonAdd = document.getElementById(button_add_id);
        const noImageExtensions = 'pdf,txt,html,mp3,mp4,doc,docx,xls,xlsx,odt,ods';

        plupload.addI18n({
            "N\/A": "N\/D",
            "tb": "TB",
            "gb": "GB",
            "mb": "MB",
            "kb": "KB",
            "b": "Bytes",
            "File extension error.": "Tipo de arquivo n\u00e3o permitido.",
            "File size error.": "Tamanho de arquivo maior que o permitido. (" + tamanhoMaximoArquivo + "MB)",
            "Duplicate file error.": "Erro: Arquivo duplicado.",
            "Init error.": "Erro ao iniciar.",
            "HTTP Error.": "Erro HTTP.",
            "%s specified, but cannot be found.": "M\u00e9todo de envio <b>%s<\/b> especificado, mas n\u00e3o p\u00f4de ser encontrado.",
            "You must specify either browse_button or drop_element.": "Voc\u00ea deve especificar o bot\u00e3o para escolher(browse_button) os arquivos ou o elemento para arrastar(drop_element).",
            "Select files": "Selecione os arquivos",
            "Add files to the upload queue and click the start button.": "Adicione os arquivos \u00e0 fila e clique no bot\u00e3o \"Iniciar o envio\".",
            "List": "Listagem",
            "Thumbnails": "Miniaturas",
            "Filename": "Nome do arquivo",
            "Status": "Status",
            "Size": "Tamanho",
            "Drag files here.": "Arraste os arquivos pra c\u00e1",
            "Add Files": "Adicionar arquivo(s)",
            "Start Upload": "Iniciar o envio",
            "Stop Upload": "Parar o envio",
            "File count error.": "Erro na contagem dos arquivos",
            "File: %s": "Arquivo: %s",
            "File: %s, size: %d, max file size: %d": "Arquivo: %s, Tamanho: %d , Tamanho M\u00e1ximo do Arquivo: %d",
            "%s already present in the queue.": "%s j\u00e1 presentes na fila.",
            "Upload element accepts only %d file(s) at a time. Extra files were stripped.": "S\u00f3 s\u00e3o aceitos %d arquivos por vez. O que passou disso foi descartado.",
            "Image format either wrong or not supported.": "Imagem em formato desconhecido ou n\u00e3o permitido.",
            "Runtime ran out of available memory.": "M\u00e9todo de envio ficou sem mem\\u00f3ria.",
            "Resoultion out of boundaries! <b>%s<\/b> runtime supports images only up to %wx%hpx.": "Resolu\u00e7\u00e3o fora de tamanho. O m\u00e9todo de envio <b>%s<\/b> suporta imagens com no m\u00e1ximo %wx%hpx.",
            "Upload URL might be wrong or doesn't exist.": "URL de envio incorreta ou inexistente",
            "Close": "Fechar",
            "Uploaded %d\/%d files": "%d\\\/%d arquivo(s) enviados(s)",
            "%d files queued": "%d arquivo(s)",
            "Error: File too large:": "Erro: Arquivo muito grande:",
            "Error: Invalid file extension:": "Erro: Extens\u00e3o de arquivo inv\u00e1lida:",
        });

        var uploader = new plupload.Uploader({
            runtimes: 'html5',
            browse_button: [button_add_id, droppable_id],
            container: container,
            drop_element: droppable_id,
            filters: {
                max_file_size: tamanhoMaximoArquivo + 'mb',
                mime_types: [
                    {title: "Image files", extensions: "jpg,gif,png,jpeg"},
                    {title: "Outros", extensions: noImageExtensions},
                ],
            },
            init: {
                PostInit: function() {
                    document.getElementById(browser_support_id).remove();

                    showOverlay();

                    document.addEventListener('dragover', dragoverHandler);
                    document.addEventListener('drop', hideOverlay);

                },
                FilesAdded: function(up, files) {
                    container.classList.remove('showOverlay');
                    if (bolAjax) {
                        uploadAjax(up, files);
                    } else {
                        plupload.each(files, function(file) {
                            var newContainerEl = document.createElement('div');
                            newContainerEl.className = EPROC_FD.CONTAINER_CLASS;
                            newContainerEl.dataset['fileid'] = file.id;
                            var fileInputId = 'file_' + namePrefix + file.id;

                            var containerImgId = 'img-container_' + file.id;
                            var html = '';

                            html += '<div class="eproc-fd-img" id="' + containerImgId + '">';
                            html += '<input type="button" title="Remover este arquivo" class="eproc-fd-img-remove" value="X">';
                            html += '<div class="eproc-fd-img-header">' + file.name + ' ('
                                + plupload.formatSize(file.size) + ')</div>';
                            html += '</div>';

                            if (bolComDescricao) {
                                html += '<div class="eproc-fd-img-additional-info">';
                                html += "<textarea name='" + namePrefix + "[" + file.id
                                    + "][descricao]' placeholder='(Opcional) Descrição...'></textarea>";
                                html += '</div>';
                            }
                            html += "<input type='hidden'  	name='" + namePrefix + "[" + file.id
                                + "][type]'			value='" + file.type + "'/>";
                            html += "<input type='hidden' 	name='" + namePrefix + "[" + file.id
                                + "][filename]'		value='" + file.name + "'/>";
                            html += "<input type='hidden' 	name='" + namePrefix + "[" + file.id
                                + "][size]'			value='" + file.size + "'/>";
                            html += "<input type='hidden' 	name='" + namePrefix + "[" + file.id
                                + "][content_base64]'  id='" + fileInputId + "'/>";

                            newContainerEl.innerHTML = html;

                            filelistContainer.insertBefore(newContainerEl, elButtonAdd.parentElement);
                            showImagePreview(file, containerImgId);
                            registerHandlers(containerImgId);
                            var reader = new FileReader();
                            reader.readAsDataURL(file.getSource().getSource());
                            reader.onloadend = function() {
                                var dataUrl = reader.result;
                                var base64 = dataUrl.split(',')[1];
                                dataUrl = null;
                                document.getElementById(fileInputId).value = base64;
                            }
                        });
                    }

                    hideOverlay();
                },
                Error: function(up, err) {
                    let msg = "Erro ao fazer upload.\n(#" + err.code + ") " + err.message;
                    alert(msg);
                    console.error(msg)
                },
            },
        });

        function registerHandlers(id) {

            var container = $("#" + id).parent('.' + EPROC_FD.CONTAINER_CLASS);

            container.on('click', '.eproc-fd-img-remove', function() {
                var parent = $(this).parents('.' + EPROC_FD.CONTAINER_CLASS);
                var fileId = parent.data('fileid');
                uploader.removeFile(fileId);
                parent.remove();
            });
            //todo ao clicar no botão de excluir, remover o arquivo e o html associado

        }

        uploader.init();
        return uploader;
    }

    function showImagePreview(file, containerImgId) {
        var fileExt = plupload.moxie.core.utils.Mime.getFileExtension(file.name);
        var imageContainer = $('#' + containerImgId);

        switch (fileExt) {
            case 'pdf':
            case 'txt':
                imageContainer.addClass('img-' + fileExt);
                break;
            default:
                var preloader = new plupload.moxie.image.Image();

                preloader.onload = function() {
                    preloader.resize({
                        width: 100,
                        height: 100,
                        crop: true,
                        fit: true,
                    });

                    imageContainer.append('<img class="eproc-fd-img-preview" src="' + preloader.getAsDataURL() + '"/>');
                };
                preloader.load(file.getSource());
                break;
        }

    }

    /**
     * Verifica se a data passada por parâmetro foi preenchida corretamente e se é maior que a data atual.
     * @returns {boolean}
     */
    function hasErrorData(dataCompleta) {
        var hasError = false;

        if (dataCompleta.length < 10) {
            hasError = true;
        } else {
            var dataForm = dataCompleta.substr(0, 10).split("/");
            var horaForm = dataCompleta.substr(11, 8).split(":");

            for (var i = 0; i < 3; i++) {
                if (!horaForm[i]) {
                    horaForm[i] = '0';
                }
            }

            dataForm = new Date(dataForm[2], dataForm[1] - 1, dataForm[0], horaForm[0], horaForm[1], horaForm[2], 0);
            var dataAtual = new Date(Date.now());

            if (dataAtual.getTime() < dataForm.getTime()) {
                hasError = true;
            }
        }

        return hasError;
    }

    /**
     * Verifica se alguma parte foi selecionada do select cujo id é idPartes
     * @param idPartes - o id do select
     * @returns {boolean}
     */
    function isParteSelecionada(idPartes) {
        var isParteSelecionada = false;
        $('#' + idPartes + ' option').each(function() {
            if ($(this).is(':selected')) {
                isParteSelecionada = true;
                return false;
            }
        });

        return isParteSelecionada;
    }

    /**
     * Se o checkbox for desmarcado, habilita select, senão desabilita
     * @param idCheckBox - o id do checkbox
     * @param idSelect - o id do select a ser (des)habilitado
     */
    function changeSelectFromCheckBox(idCheckBox, idSelect) {
        var isChecked = $('input[id=' + idCheckBox + ']:checked').length > 0;

        if (isChecked) {
            $('select#' + idSelect + ' option').prop("selected", false);
            $('#' + idSelect).prop('disabled', true);
            //$('#id_partes').css('background-color', '#dddddd');
        } else {
            $('#' + idSelect).prop('disabled', false);
            //$('#id_partes').css('background-color', '#f9f9f9');
        }
    }

    /**
     * Adiciona um id a um label e redireciona para ele
     * @param forLabel - o atributo for do label
     */
    function addIdToLabelAndRedirect(forLabel) {
        var label = $("label[for=" + forLabel + "]");
        label.prop('id', 'lbl' + forLabel);
        location.href = "#lbl" + forLabel;
    }

    /**
     * Ver EprocINT::spinner
     * @param isSmall
     * @returns {string}
     */
    function createSpinner(isSmall) {
        const isSmallClass = isSmall ? ' spinner-border-sm' : '';
        return `<div class="spinner-border${isSmallClass}" role="status"><span class="sr-only">Carregando...</span></div>`
    }

    function updateSpinnerContent($element, popoverContent) {
        $element.attr('data-content', popoverContent)
                .data('bs.popover').setContent(popoverContent)
        $element.popover('update')
    }

    window.genericCall = genericCall;
    window.genericAjax = genericAjax;
    window.genericAjaxPromise = genericAjaxPromise;
    window.submitAjax = submitAjax;
    window.exibirAlertaBS4 = exibirAlertaBS4;
    window.marcarTodos = marcarTodos;
    window.getOptionsHtml = getOptionsHtml;
    window.areaUploadMultiplosArquivos = areaUploadMultiplosArquivos;
    window.hasErrorData = hasErrorData;
    window.isParteSelecionada = isParteSelecionada;
    window.changeSelectFromCheckBox = changeSelectFromCheckBox;
    window.addIdToLabelAndRedirect = addIdToLabelAndRedirect;
    window.createSpinner = createSpinner;
    window.updateSpinnerContent = updateSpinnerContent;
})(window, document, $ || jQuery);